import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Coordinates')
export class Coordinates {
	@JsonProperty('lat', Number, true) lat: number | undefined;
	@JsonProperty('lng', Number, true) lng: number | undefined;
}

@JsonObject('Address')
export class Address {
	@JsonProperty('id', String, true) id: string = '';
	@JsonProperty('full_street', String) fullStreet: string = '';
	@JsonProperty('full_floor', String, true) fullFloor: string | undefined;
	@JsonProperty('comments', String, true) comments: string | undefined;
	@JsonProperty('coordinates', Coordinates, true) coordinates: Coordinates | undefined;
}