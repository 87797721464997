import { Any, JsonObject, JsonProperty } from 'json2typescript';

import { Store } from 'models/Store';
import { Offer } from 'models/Offer';
import { Address } from 'models/Address';
import { Customer } from 'models/Customer';
import { UserDeliveryBoy } from 'models/UserDeliveryBoy';
import { CustomerCoupon } from './CustomerCoupon';
import moment from 'moment';

const statusLang: { [key:string]: string } = {
	pending: 'Pendiente',
	in_course: 'En preparacion',
	in_way: 'Retirado',
	prepared: 'Preparado',
	cancelled: 'Cancelado',
	complete: 'Completado',
};

@JsonObject('OrderDeliveryBoy')
export class OrderDeliveryBoy {
	@JsonProperty('user', UserDeliveryBoy, true) user?: UserDeliveryBoy;
	@JsonProperty('has_user', Boolean, true) hasUser: boolean = false;
}

@JsonObject('OrderDelivery')
export class OrderDelivery {
	@JsonProperty('address', Address) address: Address | undefined;
	@JsonProperty('delivery_price', Number) deliveryPrice: number = 0;
	@JsonProperty('surcharge', Number) surcharge: number = 0;
	@JsonProperty('tip', Number) tip: number = 0;
}

@JsonObject('OrderOffer')
export class OrderOffer {
	@JsonProperty('offer', Offer) offer: Offer = new Offer();
	@JsonProperty('amount', Number) amount: number = 0;
	@JsonProperty('price', Number) price: number = 0;
	@JsonProperty('options', [[Number]]) _options: number[][] = [];

	opts: { option: number, count : number }[][] | undefined = undefined;

	public get options() : { option: number, count : number }[][] {
		if (!this.opts) {
			this.opts = this._options.map<{ option: number, count : number }[]>(optionOptions => {
				return optionOptions.reduce<{ option: number, count : number }[]>((selectedOptions, selectedOption) => {
					if (selectedOptions.length !== 0 && selectedOptions[selectedOptions.length - 1].option === selectedOption) {
						selectedOptions[selectedOptions.length - 1].count++;
					}
					else {
						selectedOptions.push({ option: selectedOption, count: 1 });
					}

					return selectedOptions;
				}, [])
			});
		}

		return this.opts;
	}
}

@JsonObject('OrderCustomerCoupon')
export class OrderCustomerCoupon {
	@JsonProperty('customer_coupon', CustomerCoupon) customerCoupon: CustomerCoupon = new CustomerCoupon();
	@JsonProperty('discount', Number) discount: number = 0;
	@JsonProperty('store_debt', Number) storeDebt: number = 0;
	@JsonProperty('delivery_debt', Number) deliveryDebt: number = 0;
}

@JsonObject('Order')
export class Order {
	@JsonProperty('id', String) id: string = '';
	@JsonProperty('code', String) code: string = '';
	@JsonProperty('status', String) status: string = '';
	@JsonProperty('estimated_pickup_time', Number) estimatedPickupTime: number = 0;
	@JsonProperty('delivery', OrderDelivery, true) delivery: OrderDelivery | undefined;
	@JsonProperty('offers', [OrderOffer]) offers: OrderOffer[] = [];
	@JsonProperty('customer', Customer) customer: Customer = new Customer();
	@JsonProperty('is_cash', Boolean) isCash: boolean = false;
	@JsonProperty('delivery_boy', OrderDeliveryBoy) deliveryBoy?: OrderDeliveryBoy;
	@JsonProperty('created_at', String) createdAt: string = '';
	@JsonProperty('store', Store) store: Store = new Store();
	@JsonProperty('comments', String, true) comments?: string;
	@JsonProperty('rating', Number, true) rating?: number;
	@JsonProperty('rating_message', String, true) ratingMessage?: string;
	@JsonProperty('customer_coupon', OrderCustomerCoupon, true) customerCoupon?: OrderCustomerCoupon;
	@JsonProperty('custom_delivery', Boolean) customDelivery: boolean = false;
	@JsonProperty('has_delivery_boy', Boolean, true) hasDeliveryBoy?: boolean;
	@JsonProperty('status_time', Any, true) statusTime: any = undefined;
	@JsonProperty('is_transference', Boolean, true) isTransference: boolean = false;
	
	/**
	 * Get order total
	 **/
	public get total(){
		let total = 0;

		for (let i = 0; i < this.offers.length; ++i) {
			total += this.offers[i].amount * this.offers[i].price;
		}

		if (this.customDelivery && this.delivery) {
			total += this.delivery.deliveryPrice;
			total += this.delivery.surcharge;
			total += this.delivery.tip;
		}

		return total;
	}

	public get discount() : number {
		if (this.customerCoupon) {
			
			if (this.deliveryBoy?.hasUser && !this.deliveryBoy.user && this.delivery?.address) {
				if (this.isCash) {
					return this.customerCoupon.discount;
				}
				else {
					// With online payments we do not collect the fee so the client should not pay them
					return this.customerCoupon.storeDebt + this.customerCoupon.deliveryDebt;
				}
			}
			
			if (!this.isCash) {
				return this.customerCoupon.storeDebt;
			}
		}
		
		return 0;
	}
	

	/**
	 * Get order status formated
	 **/
	public get statusFormatted(): string{
		return statusLang[this.status] || this.status;
	}
	
	public get statusMinutes() : number {
		if (this.statusTime[this.status]) {
			return moment().diff(moment(this.statusTime[this.status]), 'minutes');
		}

		return 0;
	}
}
