import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Customer')
export class Customer {
	@JsonProperty('id', String) id: string = '';
	@JsonProperty('first_name', String) firstName: string = '';
	@JsonProperty('last_name', String) lastName: string = '';
	@JsonProperty('mobile', String) mobile: string = '';

	public get fullName() : string {
		return `${this.firstName} ${this.lastName}`;
	}

    public get whatsappLink() {
        return this.mobile ? `https://api.whatsapp.com/send?phone=${this.mobile.replace(/\D/g, '')}` : '';
    }
}
