import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserDeliveryBoy')
export class UserDeliveryBoy {
	@JsonProperty('id', String) id: string = '';
	@JsonProperty('phone', String, true) phone?: string;
	@JsonProperty('first_name', String) firstName: string = '';
	@JsonProperty('last_name', String) lastName: string = '';

	public get fullName() : string {
		return `${this.firstName} ${this.lastName}`;
	}
}
