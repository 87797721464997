import React, { useState } from 'react';

import { IonButton, IonModal, IonContent, IonRow, IonCol, IonGrid } from '@ionic/react';

import { Order } from 'models/Order';

const phones = {
	Fede: 'Comunicarse con Federico al 11 5951-6159',
};

const schedules = [
	[
		{ start: 0, end: 23, phone: phones.Fede },
	],
	[
		{ start: 0, end: 23, phone: phones.Fede },
	],
	[
		{ start: 0, end: 23, phone: phones.Fede },
	],
	[
		{ start: 0, end: 23, phone: phones.Fede },
	],
	[
		{ start: 0, end: 23, phone: phones.Fede },
	],
	[
		{ start: 0, end: 23, phone: phones.Fede },
	],
	[
		{ start: 0, end: 23, phone: phones.Fede },
	]
];

interface OrderHelpButtonProps {
	order: Order
}

const OrderHelpButton: React.FC<OrderHelpButtonProps> = ({ order }) => {
	const [showHelp, setShowHelp] = useState(false);

	const day = new Date().getDay();

	const winimSupportSchedule = schedules[day].find(schedule => {
		const hour = new Date().getHours();
	
		return schedule.start <= hour && schedule.end >= hour; 
	});

	return (
		<>
			{showHelp && <IonModal isOpen={true} onDidDismiss={() => setShowHelp(false)}>
				<IonContent className="ion-padding" onClick={(e) => {e.stopPropagation();}}>
					<IonGrid className="vertical-center">
						{/* Customer */}
						<IonRow class="text-m ion-text-center">
							<IonCol><b>Cliente:</b> { order.customer.fullName } | { order.customer.mobile }</IonCol>
						</IonRow>
						<hr />

						{/* Delivery boy */}
						{order.deliveryBoy?.user && <IonRow class="text-m ion-text-center">
							<IonCol><b>Repartidor:</b>  { order.deliveryBoy.user.fullName } { order.deliveryBoy.user.phone && ' | ' + order.deliveryBoy.user.phone }</IonCol>
						</IonRow>}
						<hr />

						{/* Support */}
						{ winimSupportSchedule && <IonRow class="text-m ion-text-center">
							<IonCol><b>Cobros, pagos y consultas WINIM:</b> { winimSupportSchedule.phone }</IonCol>
						</IonRow>
						}

						{/* Close */}
						<IonRow class="text-m ion-text-center">
							<IonCol><IonButton size="small" onClick={() => setShowHelp(false)}>Cerrar</IonButton></IonCol>
						</IonRow>

					</IonGrid>
				</IonContent>
			</IonModal>}

			<IonButton size="small" onClick={(e) => {e.stopPropagation();setShowHelp(true)}}>Ayuda</IonButton>
		</>
	);
};

export default OrderHelpButton;
