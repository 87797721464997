import { useState, useEffect, ReactElement } from 'react';
import { AnimationBuilder, Mode } from '@ionic/core';

import StateStore from 'libraries/StateStore';

interface ModalOptions {
    presentingElement?: HTMLElement;
    showBackdrop?: boolean;
    backdropDismiss?: boolean;
    cssClass?: string | string[];
    animated?: boolean;
    swipeToClose?: boolean;
    mode?: Mode;
    keyboardClose?: boolean;
    id?: string;
    enterAnimation?: AnimationBuilder;
    leaveAnimation?: AnimationBuilder;
}

interface Modal {
    id: string,
    options?: ModalOptions,
    content: ReactElement,
    dismiss: (value?: any) => void,
    onDidDismiss: Promise<any>
}

class ModalController extends StateStore {
    constructor () {
        super();

        this.setStateProperty('activeModals', []);
    }
    
    /**
     * Create modal
     * 
     * @param {ReactElement} content Content for the modal
     * @param {ModalOptions} options Options for the modal
     **/
    create (content: ReactElement, options?: ModalOptions): Modal {
        const modals = this.getStateProperty('activeModals') as Array<Modal>;

        // Id for the modal
        let id: string;

        // eslint-disable-next-line no-loop-func 
        do { id = String(Math.random() * 100000); } while (typeof modals.find(modal => modal.id === id) !== 'undefined');

        // Function to resolve on did dismiss promise
        let resolveOnDidDismiss : (value: any) => void = () => {};
        
        // On did dismiss promise
        const onDidDismiss = new Promise<any>(resolve => { resolveOnDidDismiss = resolve; });
        
        // Dismiss function
        const dismiss = (value?: any) => {
            // Actual active modals
            const modals = this.getStateProperty('activeModals') as Array<Modal>;
            
            // Find actual modal index
            const modalIndex = modals.findIndex(modal => modal.id === id);
            
            if (modalIndex !== -1) {
                // Resolve the promise
                resolveOnDidDismiss(value);
                
                // Copy modals to avoid modify the reference
                const newModals = [...modals];

                // Remove modal
                newModals.splice(modalIndex, 1);

                // Set new modals
                this.setStateProperty('activeModals', newModals);
            }
        };
        
        // Create the new modal
        const newModal: any = { id, content, options, dismiss, onDidDismiss };

        // Set new modals
        this.setStateProperty('activeModals', [...modals, newModal]);

        // Dissmiss modals if the same modal content is open
        for (let i = 0; i < modals.length; i++) {
            if (modals[i].content.type === content.type) {
                modals[i].dismiss();
            }
        }
        
        return newModal;
    }

    /**
     * Dismiss the top modal
     **/
    async dismiss (value?: any): Promise<void> {
        const modals = this.getStateProperty('activeModals') as Array<Modal>;
        
        if (modals.length > 0) {
            modals[modals.length - 1].dismiss(value);
        }
    }
}

const infoHelper = new ModalController();

export function useActiveModals () {
    const [activeModals, setActiveModals] = useState(infoHelper.getStateProperty('activeModals') as Array<Modal>);

    useEffect(() => {
        const id = infoHelper.on('activeModals', setActiveModals);

        return () => {
            infoHelper.removeListener('activeModals', id);
        };
    }, [activeModals]);

    return activeModals;
}

export default infoHelper;
