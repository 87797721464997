import React, { useState } from 'react';
import { IonContent, IonPage, IonButton, IonRow, IonCol, IonText, IonFooter, IonInput, IonLabel, IonBadge, IonIcon } from '@ionic/react';
import { navigateOutline, star } from 'ionicons/icons';

import OrderService from 'services/OrderService';
import InfoHelper from 'libraries/InfoHelper';
import ModalController from 'libraries/ModalController';

import { Order } from 'models/Order';
import { Schedule } from 'models/Store';

import DataError from 'components/DataError';
import OrderHelpButton from 'components/OrderHelpButton';

import './OrdersDetails.scss';

interface OrdersDetailsProps {
	order: Order;
	dismiss?: () => void;
};

const OrdersDetails: React.FC<OrdersDetailsProps> = ({ order, dismiss }) => {
	const [ preparationTime, setPreparationTime ] = useState('');
	const [ showPreparationTime, setShowPreparationTime ] = useState(false);

	async function updateStatus(status: 'in_course' | 'prepared' | 'cancelled' | 'in_way' | 'complete') {
		let message;

		if (status === 'complete') {
			message = '¿Seguro que quiere marcar este pedido como completado?' 
		}
		else if (status === 'in_course') {
			message = '¿Seguro que quiere marcar este pedido como en curso?';
		}
		else if (status === 'prepared') {
			message = '¿Seguro que quiere marcar este pedido como preparado?';
		}
		else if (status === 'in_way') {
			message = '¿Seguro que quiere marcar este pedido como en camino?';
		}
		else {
			message = '¿Seguro que quiere cancelar este pedido?';
		}

		await InfoHelper.showAlert({
			header: 'Esta acción es irreversible',
			message,
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel',
					cssClass: 'secondary'
				}, 
				{
					text: 'Confirmar',
					handler: async () => {
						await InfoHelper.showLoading();

						try {
							await OrderService.updateStatus(order.id, status, status === 'in_course' ? preparationTime : undefined);

							await InfoHelper.hideLoading();

							dismiss ? dismiss() : ModalController.dismiss();
						}
						catch (err) {
							const error: any = err;

							console.log(error);

							await InfoHelper.hideLoading();

							if (!error?.response?.data?.data_errors) {
								await InfoHelper.showErrorAlert();
							}
							else if(error?.response?.data?.data_errors?.time_to_prepare_order) {
								setPreparationTime('');
							}
						}
					}
				}
			]
		});
	}

	return (
		<IonPage className="orders-details-page">
			<IonContent className="ion-padding">
				<IonRow className="ion-text-center">
					<IonCol>
					<div className="vertical-center">
						<small><b>Código</b></small>
						<br />
						{ order.code }
					</div>
					</IonCol>
					<IonCol>
					<div className="vertical-center">
						{ order.store.name }
						<br/>
						<small>{ 
							order.delivery 
								? order.customDelivery 
									? 'Delivery a cargo del local' 
									: <IonText color='success'><b>Delivery a cargo de WINIM</b></IonText>
								: 'Take Away' 
						}</small>

						{ !!(order.status === 'in_course' && !!order.delivery && !order.customDelivery && !order.hasDeliveryBoy) &&
							<>
								<br/>
								<IonText color='danger'><b>No prepare el pedido hasta que tenga repartidor asignado</b></IonText>
							</>
						}
					</div>
					</IonCol>
					<IonCol>
					<div className="vertical-center">
						<OrderHelpButton order={order} />
						<a href={`/orders/${order.id}/ticket`} target="_blank" rel="noopener noreferrer">
							<IonButton size="small">Imprimir</IonButton>
						</a>
					</div>
					</IonCol>

					{!!order.estimatedPickupTime && <IonCol size="12" className="ion-text-center">
						<b>
							Horario de {order.delivery ? ' delivery' : ' retiro'}: { Schedule.format(order.estimatedPickupTime) }
						</b>
					</IonCol>}
				</IonRow>
				<hr />
				
				{ order.status === 'pending' && order.isTransference &&
						<>
							<IonRow className="ion-text-center">
								<IonCol>
									<IonText color='danger'><b>
										Comuniquese con { order.customer.fullName } al { order.customer.mobile } para coordinar la transferencia de dinero.
									</b></IonText>
									<br/>
									<br/>
									<IonText color='success'><a href={order.customer.whatsappLink} target="_blank" rel="noopener noreferrer">
										Click aqui para abrir chat de WhatsApp
									</a></IonText>
								</IonCol>
							</IonRow>
							<hr />
						</>
					}
				{/* Order rating */}
				{order.rating != null && <IonRow class="text-m ion-text-center rating">
					<IonCol>
						<b>Calificacion:</b> <span>{order.rating}</span> <IonIcon icon={star}></IonIcon> <span>{order.ratingMessage}</span>
					</IonCol>
				</IonRow>}
				{order.rating && <hr />}
				
				<IonRow class="text-m ion-text-center">
					{/* Customer */}
					<IonCol sizeSm="6" size="12"><b>Cliente:</b> { order.customer.fullName }</IonCol>
				
					{/* Payment Method */}
					<IonCol sizeSm="6" size="12">
						<b>Metodo de pago:</b>&nbsp; 
						{ order.isCash ? <IonBadge className="text-middle" color="danger">{ order.isTransference ? 'Transferencia' : 'Efectivo' }</IonBadge> : <IonBadge className="text-middle" color="success">Tarjeta</IonBadge> }
					</IonCol>
				
					{/* Delivery address */}
					{ order.customDelivery && order.delivery?.address && <IonCol size="12" className="ion-no-padding"><IonRow>
						<IonCol sizeSm="6">
							<b>Enviar a:</b>&nbsp;{ order.delivery.address.fullStreet }
							{ order.delivery.address.fullFloor && ', ' + order.delivery.address.fullFloor }
							{ order.delivery.address.comments && '. Comentarios: ' + order.delivery.address.comments }

							{/* Google maps button */}
							{ order.delivery.address.coordinates && <a rel="noopener noreferrer" className="ion-margin-start" target="_blank" href={'https://www.google.com/maps/dir/'+order.delivery.address.coordinates.lat +',' + order.delivery.address.coordinates.lng}> 
								<IonText color="primary">
									<IonIcon icon={navigateOutline}></IonIcon>
								</IonText>
							</a>}
						</IonCol>
						<IonCol sizeSm="6">
							<b>Precio:</b>&nbsp;${ order.delivery.deliveryPrice }
							{ order.delivery.surcharge ? ' + Recargo $' + order.delivery.surcharge : '' }
							{ order.delivery.tip ? ' + Propina $' + order.delivery.tip : '' }
						</IonCol>
						<IonCol size="12">
							<b>Numero del cliente:</b> { order.customer.mobile }
						</IonCol>
					</IonRow></IonCol>}

					{!!order.comments && <IonCol size="12">
						<b>Comentarios:</b> { order.comments }
					</IonCol>}
				</IonRow>
				<hr />

				{/* Offers */}
				{ order.offers.map((offer, i) => <IonRow key={i} className="ion-padding-horizontal">
					<IonCol>
						<span className="text-m ion-margin-end">{ offer.offer.name }</span>
						{offer.offer.description && <IonText color="medium" className="text-m ion-margin-end"><br/>{ offer.offer.description }</IonText>}
						{offer.offer.options && offer.offer.options.length > 0 && <div className="text-sm ion-padding-horizontal">
							{offer.offer.options.map((option, j) => <span key={j}>
								<IonText  className="text-semi-bold">{ option.label }: &nbsp;</IonText>
								
								{offer.options[j].map((selectedOption, k) => <div key={k} className="ion-margin-start">
									{ option.count > 1 && selectedOption.count } { option.options[selectedOption.option].name }
								</div>)}
							</span>)}
						</div>}
					</IonCol>
					<IonCol size="auto">
						<span className="text-sm"><b>{ offer.amount }</b> x ${ offer.price }</span>
					</IonCol>
					<IonCol size="12">
						<hr />
					</IonCol>
				</IonRow>)}

				{/* Discount */}
				{ !!order.discount && <>
					<IonRow className="text-m ion-text-right ion-padding-horizontal">
						<IonCol><b>Descuento:</b> - ${ order.discount }</IonCol>
					</IonRow> 

					<IonRow className="text-m ion-text-right ion-padding-horizontal">
						<IonCol><b><u>A pagar por el cliente:</u></b> ${ order.total - order.discount }</IonCol>
					</IonRow> 
				</>
				}

				{/* Total */}
				<IonRow className="ion-text-right ion-padding-horizontal">
					<IonCol><b>Total:</b> ${ order.total }</IonCol>
				</IonRow>
			</IonContent>
			<IonFooter class="ion-padding ion-text-center">
				{!showPreparationTime && <>
					{/* Confirm order button */}
					{ order.status === 'pending' && (
							order.isTransference ? 
							<IonButton color="success" size="small" onClick={() => setShowPreparationTime(true)}>Ya recibi la transferencia, aceptar pedido</IonButton> : 
							<IonButton color="success" size="small" onClick={() => setShowPreparationTime(true)}>Aceptar pedido</IonButton>
						)
					}

					{/* Mark order as prepared button */}
					{ (order.status === 'pending' || order.status === 'in_course') && (
							order.isTransference ? 
							<IonButton size="small" onClick={() => updateStatus('prepared')}>Ya recibi la transferencia, pedido listo para recoleccion</IonButton> :
							<IonButton size="small" onClick={() => updateStatus('prepared')}>Listo para recoleccion</IonButton>
						)
					}

					{/* Reject order button */}
					{ order.status === 'pending' && 
						<IonButton color="danger" size="small" onClick={() => updateStatus('cancelled')}>Rechazar</IonButton>}

					{/* Mark order in way button */}
					{ 
						order.status === 'prepared' && ((order.deliveryBoy?.hasUser || !order.delivery) ? 
							<IonButton color="success" size="small" onClick={() => updateStatus(order.delivery ? 'in_way' : 'complete')}>Retirado</IonButton> :
							'Aun no fue tomado por ningun repartidor'
						)
					}

					{/* Mark order as complete button */}
					{ order.status === 'in_way' && order.customDelivery &&
						<IonButton color="success" size="small" onClick={() => updateStatus('complete')}>Entregado</IonButton>}
				</>}

				{/* Preparation time */}
				{showPreparationTime && <>
					<IonLabel position="stacked">Tiempo estimado de preparacion (en minutos)</IonLabel>
					<IonInput value={preparationTime} onIonChange={e => setPreparationTime(e.detail.value as string)} placeholder="Ingresa los minutos"></IonInput>
					<DataError name="time_to_prepare_order" />
					<IonButton color="success" size="small" onClick={() => updateStatus('in_course')}>Confirmar</IonButton>
					<IonButton color="danger" size="small" onClick={() => setShowPreparationTime(false)}>Cancelar</IonButton>
				</>}
			</IonFooter>
		</IonPage>
	);
};

export default OrdersDetails;
