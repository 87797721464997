import * as firebase from "firebase/app";
import "firebase/messaging";

import Authentication from 'libraries/Authentication';
import InfoHelper from 'libraries/InfoHelper';
import EventEmitter from 'libraries/EventEmitter';
import Server from 'libraries/Server'
import LocalStorage from 'libraries/LocalStorage'

// Your web app's Firebase configuration
var firebaseConfig = {
	apiKey: "AIzaSyDo6pE3gy3FDNejIaZ33pctavBESgQ_iuA",
	authDomain: "winim-643e4.firebaseapp.com",
	databaseURL: "https://winim-643e4.firebaseio.com",
	projectId: "winim-643e4",
	storageBucket: "winim-643e4.appspot.com",
	messagingSenderId: "237084207461",
	appId: "1:237084207461:web:cbcbccd885c59ccfd3d98c"
};

let fb: firebase.app.App, messaging: firebase.messaging.Messaging;

if (!firebase.messaging.isSupported()) {
	InfoHelper.showErrorAlert({ 
		header: 'Su navegador no es soportado por Aliados WINIM',
		subHeader: 'Le recomendamos usar Firefox o Chrome para una mejor experiencia. Lamentamos las molestias ocasionadas',
		backdropDismiss: false
	});
}
else {
	// Initialize Firebase
	fb = firebase.initializeApp(firebaseConfig);
	
	// Get messaging object
	messaging = fb.messaging();
}

// Configure messaging
const vapidKey = "BDKSpzs4uELB5ykEBvvn2uRvaitYnjC8yfqo8Z29XogOLKj-cDBhqzvUR6sqx3q5V_KUv0pV12C6PwagtrKIb-U";

class RealTime extends EventEmitter {
	/**
	 * Firebase device token
	 **/
	token?: string;

	/**
	 * True if the user is already subscribed to the notifications
	 **/
	subscribed: boolean = false;

	/**
	 * Initialize the library
	 **/
	initialize() {
		return new Promise<void>((resolve, reject) => {
			this.handle();
			this.checkPermission(resolve, reject);
		})
	}

	/**
	 * Check for notifications permission
	 **/
	checkPermission(resolve: () => void, reject: () => void) {
		if (Notification.permission === "denied") {
			InfoHelper.showErrorAlert({ 
				subHeader: 'Debe aceptar las notificaciones para poder utilizar la plataforma',
				backdropDismiss: false
			});
		}
		else if (Notification.permission !== "granted") {
			InfoHelper.showErrorAlert({ 
				subHeader: 'Debe aceptar las notificaciones para poder utilizar la plataforma',
				buttons: [{ text: 'Aceptar notificaciones', handler: () => {
					Notification.requestPermission().then(permission => {
						this.checkPermission(resolve, reject);
					});
				}}],
				backdropDismiss: false
			});
		}
		else {
			messaging.getToken({ vapidKey }).then(token => {
				//console.log(`Firebase token => ${token}`)
				
				this.token = token;
				this.subscribeToTopics().then(resolve).catch(reject);
			}).catch(reject);
		}
	}
	
	/**
	 * Subscribe to notifications topics
	 **/
	async subscribeToTopics(){
		if (Authentication.isAuthenticated && !this.subscribed && this.token) {
			// const oldToken = await LocalStorage.getItem(`${Authentication.storageKey}:fcmToken`);

			// if (oldToken !== this.token) {
				await Server.post('v2/users/subscribe-fcm', { token: this.token });

				await LocalStorage.setItem(`${Authentication.storageKey}:fcmToken`, this.token);
			// }

			this.subscribed = true;
		}
	}

	/**
	 * Handle notifications
	 **/
	async handle(){
		// Listen to serviceWorker messages
		navigator.serviceWorker.addEventListener('message', ({ data }) => {
			// console.log('serviceWorker Message received', data);
			
			if (data.isFirebaseMessaging) {
				const message = data.data;

				console.log('Firebase Message received', message);

				if (message.action) {
					this.emit(message.action as string, message);
				}
			}
		});

	}

	/**
	 * Reset notifications subscription
	 **/
	async reset(){
		await messaging.deleteToken();
		await LocalStorage.removeItem(`${Authentication.storageKey}:fcmToken`);
		this.subscribed = false;

		await new Promise<void>((resolve, reject) => {
			this.checkPermission(resolve, reject);
		})
	}
}

export default new RealTime()