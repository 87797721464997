import { useState, useEffect, createElement } from 'react';
import { ValueCheckingMode, OperationMode, JsonConvert } from 'json2typescript';

import { Order } from 'models/Order'

import Server from 'libraries/Server'
import EventEmitter from 'libraries/EventEmitter'
import Authentication from 'libraries/Authentication'
import RealTime from 'libraries/RealTime'
import InfoHelper from 'libraries/InfoHelper'
import ModalController from 'libraries/ModalController';
import OrdersDetails from 'pages/Orders/OrdersDetails';

class OrderService extends EventEmitter {
	private _activeOrders: Order[] = [];
	
	private initialized: boolean = false;

	private jsonConvert = new JsonConvert(OperationMode.ENABLE, ValueCheckingMode.ALLOW_NULL, true);
	
	public set activeOrders(orders : Order[]) {
		this._activeOrders = orders;

		this.emit('activeOrders', orders);
	}

	public get activeOrders() : Order[] {
		return this._activeOrders;
	}
	
	/**
	 * Initialize orders service
	 **/
	public async initialize() {
		if (this.initialized) return;

		this.initialized = true;

		if (Authentication.isAuthenticated) {
			await this.getActiveOrders();
		}

		this.subscribeToRealTime()
	}

	/**
	 * Make real time subscriptions related to orders
	 **/
	private subscribeToRealTime() {
		RealTime.on('new_order', async (data) => {
			await this.getActiveOrders();

			this.showNewOrdersAlert();
		});

		RealTime.on('update_order_delivery_boy', data => {
			if (data.order_id) {
				const order = this.activeOrders.find(order => order.id === data.order_id);

				if (order) {
					if ((!data.user_id && !order.deliveryBoy?.user) 
						|| (data.user_id && order.deliveryBoy?.user?.id === data.user_id)) {
						return;
					}
				}
			}

			this.getActiveOrders();
		});

		RealTime.on('update_order_status', async data => {
			if (typeof data.has_delivery_boy !== 'undefined') {
				data.has_delivery_boy = data.has_delivery_boy === 'true';
			}

			if (data.status === 'cancelled') {
				await this.getActiveOrders();

				return InfoHelper.showAlertWithSound({ 
					header: 'Se cancelo un pedido', 
					cssClass: 'cancelled-order-alert',
					buttons: data.order_id ? [
						'Cerrar',
						{
							text: 'Ver pedido',
							handler: async () => {
								await InfoHelper.showLoading();

								try {
									const order = await this.details(data.order_id);

									await InfoHelper.hideLoading();

									ModalController.create(createElement(OrdersDetails, { order }))
								} catch (error) {
									await InfoHelper.hideLoading();
									await InfoHelper.showErrorAlert();
								}
							}
						}
					] : ['Cerrar']
				});
			}
			else if (data.order_id && data.status) {
				const order = this.activeOrders.find(order => order.id === data.order_id);

				if (order && order.status === data.status && (typeof data.has_delivery_boy === 'undefined' || data.has_delivery_boy === order.hasDeliveryBoy)) {
					return;
				}

				await this.getActiveOrders();
	
				if (order && (typeof data.has_delivery_boy !== 'undefined' && !order.hasDeliveryBoy && data.has_delivery_boy)) {
					InfoHelper.showAlertWithSound({ 
						header: 'Repartidor asignado al pedido', 
						cssClass: 'new-order-alert',
						buttons: [
							'Cerrar',
							{
								text: 'Ver pedido',
								handler: () => ModalController.create(createElement(OrdersDetails, { order }))
							}
						]
					});
				}
				
				return;
			}
			
			await this.getActiveOrders();
		});
	}

	private showNewOrdersAlert() {
		const newOrders = this.activeOrders.filter(({ status }) => status === 'pending').length;
		
		if (newOrders > 0) {
			InfoHelper.showAlertWithSound({ 
				header: newOrders + (newOrders === 1 ? ' nuevo pedido' : ' nuevos pedidos'), 
				cssClass: 'new-order-alert',
				buttons: [
					'Cerrar',
					{
						text: `Ver ${newOrders === 1 ? 'pedido' : 'pedidos'}`,
						handler: () => {
							this.activeOrders
							.filter(({ status }) => status === 'pending')
							.forEach(order => ModalController.create(createElement(OrdersDetails, { order })))
						}
					}
				]
			});
		}
	}
	
	/**
	 * Get active orders
	 **/
	async getActiveOrders(): Promise<Array<Order>>{
		const res = await Server.get('v2/orders', { params: { in_store: 'true' }});

		const orders = this.jsonConvert.deserializeArray(res.data, Order);
		
		this.activeOrders = orders;

		return orders;
	}
	
	/**
	 * Get order details
	 * 
	 * @param {string} orderId Order id
	 **/
	async details(orderId: string): Promise<Order>{
		const res = await Server.get('v2/orders/' + orderId);

		return this.jsonConvert.deserializeObject(res.data, Order);
	}
	
	/**
	 * Update order status
	 * 
	 * @param {string} orderId Order id
	 * @param {string} status Order status
	 * @param {number} timeToPrepareOrder Estimated time to prepare order
	 **/
	async updateStatus(orderId: string, status: string, timeToPrepareOrder?: number | string): Promise<void>{
		const res = await Server.put(`v2/orders/${orderId}/status`, {
			status,
			time_to_prepare_order: timeToPrepareOrder
		});

		const orders = this.jsonConvert.deserializeArray(res.data, Order);
		
		this.activeOrders = orders;
	}
	
	/**
	 * Get orders history
	 *
	 * @param {string} start Start order
	 **/
	async get(start?: string): Promise<Array<Order>>{
		const res = await Server.get('v2/orders', { params: { start }});

		return this.jsonConvert.deserializeArray(res.data, Order);
	}
	
	/**
	 * Get orders history between dates
	 *
	 * @param {string} since Start date
	 * @param {string} until End date
	 * @param {string} start Start order
	 **/
	async getBetweenDates(since: string, until: string, start?: string): Promise<Array<Order>>{
		const res = await Server.get('v2/orders', { params: { since, until, start }});

		return this.jsonConvert.deserializeArray(res.data, Order);
	}
}

const orderService = new OrderService();

export function useActiveOrders() {
	const [activeOrders, setActiveOrders] = useState(orderService.activeOrders);
	
	useEffect(() => {
		const id = orderService.on('activeOrders', setActiveOrders);

		return () => {
			orderService.removeListener('activeOrders', id);
		};
	}, [activeOrders]);

	return activeOrders;
}


export default orderService;