import React from 'react';

import { IonText } from '@ionic/react';

import { useDataErrorsErrors } from 'libraries/DataErrors';

interface DataErrorProps {
	name: string
}

const DataError: React.FC<DataErrorProps> = (props) => {
	const errors = useDataErrorsErrors(); // eslint-disable-line @typescript-eslint/no-unused-vars
	let value;

	try {
		value = eval(`errors.${props.name}`);  // eslint-disable-line no-eval
	}
	catch(e){}

	return (
		<>
			{ 
				value && 
				<IonText className="ion-margin-bottom" style={{ display: 'block'}} color="danger">
					<small>{value}</small>
				</IonText>
			}
		</>
	);
};

export default DataError;
