import Menu from './components/Menu';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, IonLoading, IonModal } from '@ionic/react';

import Authentication, { useIsAuthenticated } from 'libraries/Authentication';
import InfoHelper, { useIsLoadingShown } from 'libraries/InfoHelper';
import { useActiveModals } from 'libraries/ModalController';
import RealTime from 'libraries/RealTime';
import LocalStorage from 'libraries/LocalStorage'

import OrderService from 'services/OrderService';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const Home = lazy(() => import('./pages/Home/Home'));
const Login = lazy(() => import('./pages/Login/Login'));
const OrdersHistory = lazy(() => import('./pages/Orders/OrdersHistory'));
const Stores = lazy(() => import('./pages/Stores/Stores'));
const Offers = lazy(() => import('./pages/Offers/Offers'));
const OrdersTicket = lazy(() => import('./pages/Orders/OrdersTicket'));
const Holidays = lazy(() => import('./pages/Holidays/Holidays'));
const AnalyticsPage = lazy(() => import('./pages/Analytics/Analytics'));

const App: React.FC = () => {
	const showLoading = useIsLoadingShown();
	const isAuthenticated = useIsAuthenticated();
	const [appInitialized, setAppInitialized] = useState(false);
	const [appError, setAppError] = useState(false);
    const activeModals = useActiveModals();

	const hideMenu = (/\/orders\/.*\/ticket.*/).test(window.location.pathname);
	const isTicket = (/\/orders\/.*\/ticket.*/).test(window.location.pathname);

	// Initialize app
	useEffect(() => {
		if (isTicket || process.env.NODE_ENV !== 'production') {
			initializeApp();
		}
		else {
			InfoHelper.showAlert({ 
				header: 'Iniciar WINIM Aliados',
				subHeader: 'Haz click para iniciar',
				backdropDismiss: false,
				cssClass: 'z-index-50000',
				buttons: [{
					text: 'Iniciar',
					handler: initializeApp
				}]
			});
		}

		async function initializeApp() {
			try {
				await LocalStorage.initialize();
				await Authentication.initialize();
				
				if (!isTicket) {
					const orderServicePromise = OrderService.initialize();
					const realTimePromise = RealTime.initialize();
					
					if (process.env.NODE_ENV === 'production') {
						await Promise.all([orderServicePromise, realTimePromise]) ;
					}

				}

				setAppInitialized(true);
			}
			catch(error) {
				console.log(error);

				InfoHelper.showErrorAlert({ 
					header: 'Ah ocurrido un error',
					subHeader: 'Intente cargar la pagina nuevamente',
					backdropDismiss: false
				});

				setAppError(true);
			}
		}
	}, [isTicket]);

	return (
		<IonApp>
			<IonLoading isOpen={showLoading || (!appInitialized && !appError)} />
			
			{appInitialized && <IonReactRouter>
					<Suspense fallback={
						<IonLoading isOpen={!showLoading} />
					}>
	
						<IonSplitPane contentId="main">
							{ isAuthenticated && !hideMenu && <Menu /> }
	
							<IonRouterOutlet id="main">
	
								{ isAuthenticated ? [
									// Render when is authenticated
									<Route key="/" path="/" component={Home} exact />,
									<Route key="/orders" path="/orders" component={OrdersHistory} exact />,
									<Route key="/stores" path="/stores" component={Stores} exact />,
									<Route key="/offers" path="/offers" component={Offers} exact />,
									<Route key="/orders/:orderId/ticket" path="/orders/:orderId/ticket" component={OrdersTicket} exact />,
									<Route key="/holidays" path="/holidays" component={Holidays} exact />,
									<Route key="/analytics" path="/analytics" component={AnalyticsPage} exact />,
								] : (
									// Render when isn't authenticated
									<Route path="/login" component={Login} exact />
								)}
	
								<Redirect to={isAuthenticated ? '/' : '/login'} />
							</IonRouterOutlet>
						</IonSplitPane>
					</Suspense>
				</IonReactRouter>}

				{activeModals.map(modal => <IonModal {...modal.options} key={modal.id} isOpen onDidDismiss={() => modal.dismiss()}>
					{modal.content} 
				</IonModal>)}
		</IonApp>
	);
};

export default App;
