import { Storage } from '@ionic/storage';

class LocalStorage {
	/**
	 * App storage
	 */
	storage = new Storage();

	async initialize() {
		await this.storage.create();
	}
	
	/**
	 * Set item in storage
	 * 
	 * @param key Item key
	 * @param value Item value
	 */
	public async setItem(key: string, value: any) {
		await this.storage.set(key, value);
	}
	
	/**
	 * Get item from storage
	 * 
	 * @param key Item key
	 */
	public getItem(key: string) {
		return this.storage.get(key);
	}
	
	/**
	 * Remove item from storage
	 * 
	 * @param key Item key
	 */
	public async removeItem(key: string) {
		await this.storage.remove(key);
	}
}

const localStorage = new LocalStorage();

export default localStorage;