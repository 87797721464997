import { useState, useEffect } from 'react';
import { AlertOptions, alertController } from '@ionic/core';
import { Howl } from 'howler';

class InfoHelper {
	/**
	 * Loading properties
	 **/
	public _isLoadingShown: boolean = false;
	
	public set isLoadingShown(v : boolean) {
		this._isLoadingShown = v;

		for (let i in this.isLoadingShownHandlers) {
			this.isLoadingShownHandlers[i](v);
		}
	}

	public get isLoadingShown() : boolean {
		return this._isLoadingShown;
	}

	/**
	 * Here are the errors hadlers
	 **/
	private isLoadingShownHandlers:  { [ name: string ]: (v: any) => void } = {}; 


	/**
	 * Add error handler
	 **/
	public addIsLoadingShownHandler(handler: (v: any) => void) {
		let id: string;

		do {
			id = String(Math.random() * 100000);
		}
		while(typeof this.isLoadingShownHandlers[id] !== 'undefined');

		this.isLoadingShownHandlers[id] = handler;

		return id;
	}

	/**
	 * Remove error handler
	 **/
	public removeIsLoadingShownHandler(id: string) {
		delete this.isLoadingShownHandlers[id];
	}

	/**
	 * Show the loading spinner
	 **/
	async showLoading(): Promise<void>{
		if (!this.isLoadingShown) {
			this.isLoadingShown = true;
		}
	}

	/**
	 * Hide the loading spinner
	 **/
	async hideLoading(): Promise<void>{
		if (this.isLoadingShown) {
			this.isLoadingShown = false;
		}
	}
	
	/**
	 * Error alert element
	 **/
	errorAlert: HTMLIonAlertElement | boolean = false;

	/**
	 * Show alert
	 *
	 * @param {AlertOptions} options Alert options
	 **/
	async showAlert(options: AlertOptions){
		const alert = await alertController.create(options);

		await alert.present();

		return alert;
	}

	/**
	 * Show error alert
	 *
	 * @param {AlertOptions} options Alert options
	 **/
	async showErrorAlert(options?: AlertOptions){
		if (this.errorAlert) {
			return;
		}
		
		this.errorAlert = true;

		this.errorAlert = await this.showAlert(options || {
			header: 'Error',
			subHeader: 'Ha ocurrido un error, por favor vuelve a intentar',
		});

		this.errorAlert.style.cssText = "z-index: 50123;";

		await this.errorAlert.onDidDismiss();

		this.errorAlert = false;
	}
	
	/**
	 * Alert with sound alert element
	 **/
	soundAlert?: HTMLIonAlertElement & { isPresent?: boolean };

	
	/**
	 * Alert with sound alert sound
	 **/
	soundAlertSound?: Howl;

	/**
	 * Show alert with sound
	 *
	 * @param {AlertOptions} options Alert options
	 * @param {number} soundTime Sound max time
	 **/
	async showAlertWithSound(options: AlertOptions, soundTime?: number){
		if (this.soundAlertSound) {
			this.soundAlertSound.unload();
			this.soundAlertSound = undefined;
		}
		
		if (this.soundAlert) {
			await this.soundAlert.dismiss();
			this.soundAlert = undefined;
		}

		this.soundAlertSound = new Howl({
			src: ['/assets/sounds/alert-1.mp3'],
			autoplay: true,
			loop: true,
		});

		const newAlert: HTMLIonAlertElement & { isPresent?: boolean } = await alertController.create(options);
		
		this.soundAlert = newAlert;

		this.soundAlert.present();
		
		newAlert.isPresent = true;

		this.soundAlert.onWillDismiss().then(() => {
			newAlert.isPresent = false;
			
			if (this.soundAlertSound) {
				this.soundAlertSound.unload();
				this.soundAlertSound = undefined;
			}
		});
		
		return this.soundAlert;
	}
}

const infoHelper = new InfoHelper();

export function useIsLoadingShown() {
	const [isLoadingShown, setIsLoadingShown] = useState(infoHelper.isLoadingShown);
	
	useEffect(() => {
		const id = infoHelper.addIsLoadingShownHandler(setIsLoadingShown);

		return () => {
			infoHelper.removeIsLoadingShownHandler(id);
		};
	}, [isLoadingShown]);

	return isLoadingShown;
}

export default infoHelper;