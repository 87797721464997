import { JsonObject, JsonProperty } from 'json2typescript';

export const statusLang: { [key:string]: string } = {
	active: 'Activo',
	hidden: 'Inactivo',
	inactive_today: 'Inactivo hoy',
};

@JsonObject('OfferOptionOption')
export class OfferOptionOption {
	@JsonProperty('name', String) name: string = '';
	@JsonProperty('price', Number, true) price: number = 0;
	@JsonProperty('retail_price', Number, true) retailPrice: number = 0;
}

@JsonObject('OfferOption')
export class OfferOption {
	@JsonProperty('label', String) label: string = '';
	@JsonProperty('has_price', Boolean, true) hasPrice: boolean = false;
	@JsonProperty('count', Number) count: number = 1;
	@JsonProperty('options', [OfferOptionOption]) options: OfferOptionOption[] = [new OfferOptionOption()];
}

@JsonObject('Offer')
export class Offer {
	@JsonProperty('id', String) id: string = '';
	@JsonProperty('name', String) name: string = '';
	@JsonProperty('description', String, true) description: string | undefined;
	@JsonProperty('options', [OfferOption], true) options: OfferOption[] | undefined;
	@JsonProperty('default_price', Number, true) defaultPrice: number | undefined;
	@JsonProperty('price', Number, true) price: number | undefined;
	@JsonProperty('retail_price', Number, true) retailPrice: number | undefined;
	@JsonProperty('start_date', Number, true) startDate: number | undefined;
	@JsonProperty('status', String, true) status: string | undefined;
	@JsonProperty('has_base_price', Boolean, true) hasBasePrice: boolean = true;

	/**
	 * Get order status formated
	 **/
	public get statusFormatted(): string{
		if (this.startDate && new Date(this.startDate) > new Date()) {
			return statusLang.inactive_today;
		}
		else {
			return this.status ? (statusLang[this.status] || this.status) : '';
		}
	}
}
