import React from 'react';
import { useLocation } from 'react-router-dom';
import { fastFoodOutline, logOutOutline, fileTrayStackedOutline, readerOutline, storefrontOutline, analyticsOutline } from 'ionicons/icons';
import {
	IonContent,
	IonFooter,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonMenu,
	IonMenuToggle,
} from '@ionic/react';

import InfoHelper from 'libraries/InfoHelper';
import Authentication from 'libraries/Authentication';
import RealTime from 'libraries/RealTime';

import './Menu.css';

interface AppPage {
	url: string;
	icon: string;
	title: string;
}

const appPages: AppPage[] = [
	{ title: 'Pedidos', url: '/', icon: fastFoodOutline },
	{ title: 'Ofertas', url: '/offers', icon: readerOutline },
	{ title: 'Locales', url: '/stores', icon: storefrontOutline },
	// { title: 'Feriados', url: '/holidays', icon: calendarOutline },
	{ title: 'Historial', url: '/orders', icon: fileTrayStackedOutline },
	{ title: 'Analytics', url: '/analytics', icon: analyticsOutline },
];

const Menu: React.FC = () => {
	const location = useLocation();

	function logout() {
		InfoHelper.showAlert({
			header: 'Cerrar session',
			subHeader: '¿Seguro que quiere cerrar sesion?',
			buttons: ['Cancelar', {
				text: 'Cerrar sesion',
				handler: async () => {
					await InfoHelper.showLoading();

					try {
						await Authentication.logout();
						await RealTime.reset();

						// Reload page
						window.location.reload();

						await InfoHelper.hideLoading();
					}
					catch (error) {
						console.log(error);

						await InfoHelper.hideLoading();
						await InfoHelper.showErrorAlert();
					}
				}
			}]
		});
	}

	return (
		<IonMenu contentId="main" type="overlay">
			<IonContent>
				<IonList id="inbox-list">
					<IonListHeader>Aliados WINIM</IonListHeader>
					{appPages.map((appPage, index) => {
						return (
							<IonMenuToggle key={index} autoHide={false}>
								<IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="root" lines="none" detail={false}>
									<IonIcon slot="start" icon={appPage.icon} />
									<IonLabel>{appPage.title}</IonLabel>
								</IonItem>
							</IonMenuToggle>
						);
					})}
				</IonList>
			</IonContent>
			<IonFooter>
				<IonList>
					<IonMenuToggle autoHide={false}>
						<IonItem lines="none" detail={false} onClick={logout} className="ion-activatable cursor-pointer">
							<IonIcon slot="start" icon={logOutOutline} />
							<IonLabel>Cerrar sesion</IonLabel>
						</IonItem>
					</IonMenuToggle>
				</IonList>
			</IonFooter>
		</IonMenu>
	);
};

export default Menu;
